import React from "react"
import Download from "./Download"
import { _axesToString, _getOrderLicenceAndCompanySize } from "./shop-utils"

const OrderList = ({ input, customer_email }) => {
  // console.log(input)
  /**
   * GROUPER PAR TYPO
   */

  const groupBy = (x, f) =>
    x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {})

  const getOrderMetadata = () => {
    return input.map(el => {
      if (typeof el.metadata !== "string") return {}

      const allLicences = el.customFields.filter(
        el => el.name.toLowerCase() !== "company size"
      )
      const licences = allLicences.filter(el => el.displayValue === "true")
      const metadata = JSON.parse(el.metadata)
      // console.log(metadata)
      const { type, slug, instance } = metadata
      const axes = type === "custom" ? metadata.axes : null
      console.log(metadata)
      // const name =
      //   type !== "instance" && metadata.instances
      //     ? metadata.instances.map(el => el.name.en)
      //     : el.name
      // console.log(axes)
      return {
        slug: slug ? slug.toLowerCase() : "",
        name: el.name,
        licences: licences,
        type: type,
        instance: instance,
        axes: axes,
      }
    })
  }
  const groupedOrder = () => {
    const orderMetadata = getOrderMetadata()
    const groupedBy = groupBy(orderMetadata, v => v.slug)
    // console.log(orderMetadata)
    // console.log(groupedBy)
    let arr = []
    for (const slug in groupedBy) {
      // console.log(groupedBy[slug])
      const type = groupedBy[slug].map(el => el.type)[0]
      const name = groupedBy[slug].map(el => el.name)
      // const axes = groupedBy[slug].map(el => el.axes)

      const axes =
        type !== "instance"
          ? groupedBy[slug][0].axes
          : groupedBy[slug].map(el => el.axes)
      // console.log(type, axes)

      const licences = groupedBy[slug].map(el => el.licences)

      arr.push({
        name: name,
        slug: slug,
        licences: licences,
        axes: axes,
        type: type,
      })
    }
    return arr
  }
  // console.log(groupedOrder())
  // const groupedTypeFaces = groupedOrder()
  const orders = getOrderMetadata()
  // console.log(orders)
  // console.log(getOrderMetadata())
  return (
    <div className="order-list pl-lg-">
      {/* licenceFor: {licenceFor} */}
      {/* <pre>{JSON.stringify(orders, false, 2)}</pre> */}
      <ul>
        {orders.map((item, i) => (
          <li key={i} className=" border-b py-sm">
            {/* <pre>{JSON.stringify(item, false, 2)}</pre> */}
            <div className="row bottom-xs">
              <div className="col-md-4">
                <div className="name">
                  <div className="capitalize ">{item.name}</div>
                  <ul>
                    <li>— {item.type}</li>
                    {item.instance !== "custom" && <li>— {item.instance}</li>}
                    {item.instance === "custom" && (
                      <li>— {_axesToString(item.axes)}</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="licences">
                  <div>Licences:</div>
                  {_getOrderLicenceAndCompanySize(item)}
                </div>
              </div>
              <div className="col-xs">
                <div className="text-right">
                  <Download
                    orderMetadata={item}
                    customer_email={customer_email}
                  />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default OrderList

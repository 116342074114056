import React, { useEffect, useContext, useState } from "react"
import { _dateFr } from "../../core/utils"
// import { getCurrentUser } from "../../services/auth";
// import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";
// import { getCookie } from "../../core/utils";
import useShop from "./ShopContext"
// import TypeDownload from "./TypeDownload"
import OrderList from "./OrderList"
import { _getOrderLicenceAndCompanySize } from "./shop-utils"

const Profile = () => {
  // const { email } = getCurrentUser();
  // const { state } = useContext(SnipcartContext);
  // const { ready, userStatus } = state;
  const { ready, customer, userStatus } = useShop()
  const [data, setData] = useState(customer)
  const [orders, setOrders] = useState(null)
  const [typeface, setTypeface] = useState(null)
  const snipcartApiURL = "https://app.snipcart.com/api"
  const pizzaApiURL = "http://localhost:9000/.netlify/functions"

  const headers = new Headers()
  headers.append("Accept", "application/json")
  headers.append(
    "Authorization",
    `Basic ${btoa(process.env.GATSBY_SNIPCART_SECRET_API_KEY)}`
  )
  // console.log(customer)
  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  }

  // console.log(state);
  useEffect(() => {
    // return;
    // console.log(customer);
    if (ready) {
      if (userStatus === "SignedIn") {
        // const customerID = getCookie("snipcart-customer");
        // const cartID = getCookie("snipcart-cart");
        // console.log(customerID, cartID);
        // _getUserData(cartID, customerID);
        _getUserOrders()
      } else {
        // Snipcart.api.customer.signin().then(() => {
        //   console.log("logged");
        // });
        // document.querySelector(".snipcart-customer-signin").click();
      }
    }
  }, [ready, userStatus])

  const _getUserOrders = () => {
    fetch(
      `${snipcartApiURL}/orders/?placedBy=${customer.email}`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        setOrders(result)
      })
      .catch(error => console.log("error", error))
  }

  const _getTypeDlByname = (name, axes) => {
    // console.log(_sanitizeAxes(axes));
    // return;
    fetch(`${pizzaApiURL}/type-get`, {
      method: "POST",
      body: JSON.stringify({
        name: `${name}`,
        axes: _sanitizeAxes(axes),
      }),
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        // setOrders(result);
      })
      .catch(error => console.log("error", error))
  }

  // const getTypefaceNameByAxes = (axes) => {
  //   let result = "";
  //   for (const key in axes) {
  //     result += `${key}-${axes[key]}-`;
  //   }
  //   result = result.replace(new RegExp("-" + "$"), "");
  //   return result;
  // };

  // if (orders) console.log(orders.items)
  // console.log(orders)
  const _getEmailOrLicenceFor = _customFields => {
    // console.log({ _customFields })
    if (_customFields.length === 0) return customer.email
    const licenceFor = _customFields.filter(el => el.name === "licensefor")
    // console.log({ licenceFor })
    return licenceFor.length > 0 ? licenceFor[0].value : customer.email
  }

  return (
    <div className="user-profile">
      <div className="row">
        {customer && (
          <div className="col-xs">
            {/* <pre>{JSON.stringify(customer, false, 2)}</pre> */}
            {/* <h2 className="mb-md">Your profile</h2> */}
            <ul className="w-2/3">
              <li className="flex">
                <div className="w-1/4">E-mail: </div>
                <div>{customer.email}</div>
              </li>
              <li className="flex">
                <div className="w-1/4">Adress: </div>
                <div>{customer.billingAddress.address1}</div>
              </li>
              <li className="flex">
                <div className="w-1/4">City: </div>
                <div>{customer.billingAddress.city}</div>
              </li>
              <li className="flex">
                <div className="w-1/4">Zip: </div>
                <div>{customer.billingAddress.postalCode}</div>
              </li>
              <li className="flex">
                <div className="w-1/4">Country: </div>
                <div>{customer.billingAddress.country}</div>
              </li>
              {/* <li className="flex">
                <div className="w-1/4">VAT: </div>
                <div>{customer.billingAddress.vatNumber}</div>
              </li> */}
            </ul>
          </div>
        )}
        <div className="col-xs">
          {!orders && <div className="loading text-left">Loading ...</div>}
          {orders && orders.items.length && (
            <>
              <h2 className="mb-md">Your orders</h2>

              <ul className="orders border-t- ">
                {orders.items.map((order, i) => (
                  <li key={i} className="mb-xl ">
                    <div className="border-y py-sm">
                      <div className="row">
                        <div className="col-md-4">
                          <div>#{order.invoiceNumber}</div>
                          <div className="date">
                            <span>Date :</span>
                            <span>{_dateFr(order.completionDate)}</span>
                          </div>
                        </div>

                        <div className="col-xs">
                          {/* <pre>{JSON.stringify(order, false, 2)}</pre> */}
                          {order.customFields && (
                            <ul className=" text-left ">
                              {order.customFields.map((item, j) => (
                                <li className="item" key={j}>
                                  {item.name} : {item.value}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <div className="col-xs">
                          <div className="text-right">
                            <div>Total</div>
                            <div>{order.total}€</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="items">
                      <OrderList
                        input={order.items}
                        customer_email={customer.email}
                        licenceFor={_getEmailOrLicenceFor(order.customFields)}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
export default Profile

import React, { useState } from "react"
import clsx from "clsx"
const pizzaApiURL = "/.netlify/functions"

const Download = ({ orderMetadata, customer_email }) => {
  const [busy, setBusy] = useState()
  const [status, setStatus] = useState("")
  // const [result, setResult] = useState()
  // console.log(orderMetadata)

  const _get = () => {
    setBusy(true)
    const endPoint = "type-generator-background"
    const body = {
      orderMetadata: orderMetadata,
      customer_email: customer_email,
    }
    // console.log(body)
    fetch(`${pizzaApiURL}/${endPoint}`, {
      method: "POST",
      body: JSON.stringify(body),
    })
      // .then(response => response.json())
      // .then(res => {
      //   console.log(res)
      //   alert("check your email")
      //   setBusy(false)
      //   // setStatus(res.status)
      //   setStatus("done")
      //   // setResult(res)
      // })
      .catch(error => {
        console.log("error", error)
        setBusy(false)
        setStatus(error)
      })

    // alert("check your email")
    setBusy(false)
    // setStatus(res.status)
    setStatus("done")
  }

  const _getLabel = () => {
    const { type } = orderMetadata
    return busy ? "loading" : `Generate ${type}`
  }

  return (
    <div className="donwload">
      {status !== "done" && (
        <button className={clsx("outline", status)} onClick={() => _get()}>
          {_getLabel()}
        </button>
      )}
      {/* {result && (
        <a
          className="outline button block is-active"
          href={result.fonts.link}
          download
        >
          Get your fonts
        </a>
      )} */}
      {status === "done" && (
        <p>🌶️Cooking... Email will be sent when it’s hot!</p>
      )}
    </div>
  )
}

export default Download

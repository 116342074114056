import React, { useEffect, useContext, useState } from "react"
// import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";
import SEO from "../components/seo"
import useShop from "../components/shop/ShopContext"
import Profile from "../components/shop/Profile"
import { navigate } from "gatsby"
import { _localizeText } from "../core/utils"
import Login from "../components/shop/Login"
// import Login from "../components/user/Login";
// import { UserWrapper } from "../components/user/UserContext";
// import { isLoggedIn } from "../services/auth";

const MyAccount = () => {
  const { ready, userStatus } = useShop()
  // const { state } = useContext(SnipcartContext);
  // const { ready, userStatus } = state;

  // const [isLogged, setIsLogged] = useState();
  // const isLogged = userStatus === "SignedIn";

  useEffect(() => {
    // console.log(ready, userStatus)
    if (document && ready) {
      const btn = document.querySelector(
        ".snipcart-cart-header__customer-account"
      )
      if (btn) btn.href = "/my-account/"
    }

    // if (ready && userStatus !== "SignedIn") {
    //   navigate(`/my-account/#/signin`)
    //   setTimeout(() => {}, 2000)
    //   // setIsLogged(userStatus === "SignedIn");
    // }
  }, [ready, userStatus])

  // console.log(isLoggedIn());
  return (
    <div className="my-account px-md">
      <SEO
        pageTitle={"My account"}
        pageDescription={""}
        // pageImage={image_featured.url}
        template={`template-my-account`}
        page={true}
      />

      {/* <h1 className='mb-md'>My account : {userStatus}</h1> */}
      {/*
      {!isLogged && <Login open={true} />}*/}
      <h1 className="mb-md">My account:</h1>
      {/* <button className="snipcart-customer-signin">
        <span>{_localizeText("signOut")}</span>
      </button> */}
      {userStatus === "SignedIn" && <Profile />}
      {userStatus !== "SignedIn" && <Login />}
    </div>
  )
}

export default MyAccount

export const _getVariationPriceByProductType = (variation, type) => {
  // console.log(type)
  let price = 0
  switch (type) {
    case "complete":
      price = variation.value.priceComplete
        ? variation.value.priceComplete
        : variation.value.price
      break
    case "family":
      price = variation.value.priceFamily
        ? variation.value.priceFamily
        : variation.value.price
      break
    case "custom":
      price = variation.value.priceCustom
        ? variation.value.priceCustom
        : variation.value.price
      break
    default:
      price = variation.value.price
      break
  }
  return price
}

export const _getVariationOptionPriceByProductType = (option, type) => {
  let price = 0
  switch (type) {
    case "complete":
      price = option.priceComplete ? option.priceComplete : option.price
      break
    case "family":
      price = option.priceFamily ? option.priceFamily : option.price
      break
    case "custom":
      price = option.priceCustom ? option.priceCustom : option.price
      break
    default:
      price = option.price
      break
  }
  // console.log(type, price)
  return price
}

export const _getOrderLicenceAndCompanySize = order => {
  // console.log(order)
  if (!order.licences) return
  const licences = order.licences.filter(el => el.displayValue === "true")
  return licences.map(el => el.name).toString()
  return "1"
}

export const _axesToString = raw => {
  let str = ""
  for (const key in raw) {
    if (raw[key] !== undefined) str += `${key}: ${raw[key]}, `
  }
  str = str.replace(new RegExp(", " + "$"), "")
  return str
}
